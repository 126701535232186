export const PLAN_ONBOARDING = 'P0'
export const PLAN_ADVANCED = 'P1'
export const PLAN_PREMIUM = 'P2'
export const PLAN_ELITE = 'P3'

export const PLAN_TYPE_MAIN = 'MAIN'
export const PLAN_TYPE_FISCAL_NOTES = 'FISCAL_NOTES'

export const AVAILABLE_PLAN_IDS = [PLAN_ADVANCED, PLAN_PREMIUM]
export const PLAN_TYPE_DOMAINS = 'DOMAINS'
